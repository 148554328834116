import axios from '../instance'
import type{ RateCommission ,SearchCommission,CommissionData} from './type'
import { Admin, RateCommissionData } from '@/type/commission'
export const getUserAdminById = async (shopID: string): Promise<Admin> => {
	try {
		const response = await axios.get(`/commission/admin/${shopID}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const updateRateCommission = async (rateComData: RateCommission): Promise<RateCommissionData> =>{
	try {
		const response = await axios.post('/commission/rate/',rateComData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getAllRateCommission = async (shopID: string): Promise<RateCommissionData> => {
	try {
		const response = await axios.get(`/commission/list-rate/${shopID}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getAllShopCommission = async (params: SearchCommission): Promise<CommissionData>  => {
	try {
		const response = await axios.get('/commission/sort-order-commission',{params})
		return response.data 
	} catch (error) {
		return Promise.reject(error)
	}
}