import { computed, onMounted,ref ,reactive,watch} from 'vue'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import {
	getAllShopCommission,
} from '@/api/commission/commission.service'
import dayjs from 'dayjs'
import {
	CommissionData,
} from '@/type/commission/index'
import { DATE_SELECT } from '@/constants/dashborad'
import { EVENT_NAME } from '@/constants/analytics'
import { BestSell, TotalCost } from '@/api/report/type'
import { useAnalytics } from '@/use/useAnalytics'
import xlsx from 'xlsx'
import { autofitColumns } from '@/utils/xlsx'
import { FILE_TYPE, useReactNativeEvent } from '@/use/useReactNativeEvent'

export const useCommissionData = () => {

	const shopStore = useShopStore()
	const { emitDownloadFile } = useReactNativeEvent()
	const isLoadingUser = ref<boolean>(false)
	const isLoadingOrder = ref<boolean>(false)

	const orderCommissionDetail = ref<CommissionData>()
	const userAdmin = computed(() => orderCommissionDetail.value?.user)
	const startDate = computed(() => state.datePick[0])
	const endDate = computed(() => state.datePick[1])
	const { logEvent } = useAnalytics()

	const isFromBtn = ref(true)
	const state = reactive({
		isLoading: false,
		totalCosts: {} as TotalCost,
		bestSell: [] as BestSell[],
		datePick: computed(() => shopStore.datePick),
		reconcile: {
			codTotal: 0,
			reconcileDate: null as null | string,
		},
		isGenerateReport: false,
	})

	const textHeader = ref<string>('ค่า คอมมิชชั่น ย้อนหลัง 30 วัน')
	
	const fetchCommission = async () => {
		isLoadingOrder.value = true
		state.isLoading = true
		try {
			const params={
				shopId: shopStore.selectedShopId as string,
				startDate: startDate.value,
				endDate: endDate.value
			}
			const response = await getAllShopCommission(params)
			orderCommissionDetail.value = response
		}catch(error) {
			console.log('error:', error)
		}finally {
			isLoadingUser.value = false
			isLoadingOrder.value = false
			isFromBtn.value = false
		}
		state.isLoading = false
	}

	const selectDate = (date:string) => {
		isFromBtn.value = true
		shopStore.updateDateSelect(date)
		if(date === DATE_SELECT.TODAY){
			textHeader.value = 'ค่า คอมมิชชั่น วันนี้'
			shopStore.updateDatePick([dayjs().startOf('day').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')])
			logEvent({ eventName: EVENT_NAME.onClickTodayFilterCommission })
		}
		else if(date === DATE_SELECT.SEVEN_DAYS){
			textHeader.value = 'ค่า คอมมิชชั่น ย้อนหลัง 7 วัน'
			shopStore.updateDatePick([dayjs().startOf('day').subtract(7, 'day').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')])
			logEvent({ eventName: EVENT_NAME.onClickWeekFilterCommission })
		}
		else if(date === DATE_SELECT.THIRTY_DAYS){
			textHeader.value = 'ค่า คอมมิชชั่น ย้อนหลัง 30 วัน'
			shopStore.updateDatePick([dayjs().startOf('day').subtract(30, 'day').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')])
			logEvent({ eventName: EVENT_NAME.onClick30DaysFilterCommission })
		}
		else if(date === DATE_SELECT.MONTH_AGO){
			textHeader.value = 'ค่า คอมมิชชั่น เดือนที่แล้ว'
			shopStore.updateDatePick([dayjs().subtract(1,'month').startOf('month').format('YYYY-MM-DD'), dayjs().subtract(1,'month').endOf('month').format('YYYY-MM-DD')])
			logEvent({ eventName: EVENT_NAME.onClickLastMonthFilterCommission })
		}
		else if(date === DATE_SELECT.DATE_PICK){
			const startDateNewFormat = dayjs(startDate.value).format('DD-MM-YYYY')
			const endDateNewFormat = dayjs(endDate.value).format('DD-MM-YYYY')
			shopStore.updateDatePick(shopStore.datePick)

			if(startDateNewFormat === endDateNewFormat){
				textHeader.value = `ค่า คอมมิชชั่น วันที่ ${startDateNewFormat}`
			}else{
				textHeader.value = `ค่า คอมมิชชั่น วันที่ ${startDateNewFormat} ถึง ${endDateNewFormat}`
			}
			logEvent({ eventName: EVENT_NAME.onClickFilterDateRangeCommission })
		}
	}
	const exportReconcileCODReport = () => {
		//
		state.isGenerateReport = true

		const wsData = []
		const header = [
			'ชื่อพนักงาน',
			'รหัส',
			'จำนวนออเดอร์',
			'ยอดขาย',
			'คอมมิชชั่น',
		]
		const startDateData = dayjs(startDate.value).format('DD/MM/YYYY')
		const endDateData = dayjs(endDate.value).format('DD/MM/YYYY')
		const commissionDate = startDateData + (startDateData === endDateData ? '' : ' ~ ' + endDateData)

		

		wsData.push([
			'รายงานค่าคอมมิชชั่น',
			commissionDate,
			'',
			'',
			'',
		])
		wsData.push([
			'จำนวนออเดอร์ทั้งหมด',
			orderCommissionDetail.value?.qty.toLocaleString() ?? 0,
			'',
			'',
			'',
		])
		wsData.push([
			'ยอดขายทั้งหมด',
			orderCommissionDetail.value?.salesTotal.toLocaleString() ?? 0,
			'',
			'',
			'',
		])
		wsData.push([
			'คอมมิชชั่นทั้งหมด',
			orderCommissionDetail.value?.totalCommissionAmount.toLocaleString() ?? 0,
			'',
			'',
			'',
		])
		wsData.push([]) // Empty Row
		wsData.push([]) // Empty Row
		wsData.push(header)
		for (const row of orderCommissionDetail.value?.user as any) {
			let activeName = ''
			if(row.active){
				activeName = `${row.name} (${row.role})`
			}else{
				activeName = `${row.name} (ไม่มีชื่ออยู่ในร้านนี้แล้ว)`
			}
			wsData.push([
				activeName,
				row.code,
				row.totalOrders.toLocaleString() ?? 0,
				row.sales.toLocaleString() ?? 0,
				row.commissionAmount.toLocaleString() ?? 0,
			])
		}
		wsData.push([]) // Empty Row

		const worksheet = xlsx.utils.aoa_to_sheet(wsData)
		const workbook = xlsx.utils.book_new()

		// width maximize column with
		worksheet['!cols'] = autofitColumns(wsData)
		/* Add the worksheet to the workbook */
		xlsx.utils.book_append_sheet(workbook, worksheet, 'SheetJs')

		const fileName = `commission-${commissionDate}.xlsx`
		
		if (window.ReactNativeWebView) {
			const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'base64' })

			emitDownloadFile(excelBuffer, FILE_TYPE.xlsx)

		} 
		else {
			xlsx.writeFile(workbook, fileName)
		}	
		state.isGenerateReport = false

		// logs
		logEvent({ eventName: EVENT_NAME.exportExcelCommission })
	}
	watch(startDate, async () => {
		if (!isFromBtn.value) {
			shopStore.updateDateSelect(DATE_SELECT.DATE_PICK)
			selectDate(shopStore.dateSelect)
		}
		await fetchCommission()
	})
	const dateSelected = computed(() => shopStore.dateSelect)
	onMounted(async () => {
		if(dateSelected.value === DATE_SELECT.DATE_PICK){
			shopStore.dateSelect = DATE_SELECT.THIRTY_DAYS
		}
		selectDate(shopStore.dateSelect)
		await fetchCommission()
	})

	return {
		fetchCommission,
		userAdmin,
		isLoadingUser,
		isLoadingOrder,
		orderCommissionDetail,
		selectDate,
		textHeader,
		state,
		exportReconcileCODReport,
	}
}